import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import "../styles/main.css"



const IndexPage = () => {
    return (

<main className="kontakt_wrap">
<a className="back" href="/">zurück</a>   

<div className="kontaktseite">
    <div>
        <h1>Kontakt</h1>

        <p>Martin Zerr<br />
        Kaiser-Friedrich-Stra&szlig;e 135<br />
        14469 Potsdam</p>

        <p>Telefon: auf Anfrage<br />
        E-Mail: email@martinzerr.de</p>
    </div>

    <Zoom><StaticImage src="../images/martin.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>



</div>
    


</main>


    )
}

export default IndexPage